import Layout from "../../Components/TopNav/Topnav";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../util/axios";
import { styled, tableCellClasses} from "@mui/material";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../Components/ConfirmationDialog/ConfirmationDialog";
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone";
import Cookies from "js-cookie";
import moment from "moment";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import AssessmentIcon from '@mui/icons-material/Assessment';
import Typography from "@mui/material/Typography";
import axiosAws from "axios";

const useStyles = makeStyles(() => {
  return {
    root: {
      backgroundColor: "rgb(246, 246, 247)",
    },
    boxWrapper: {
      display: "grid",
      gap: 25,
      backgroundColor: "rgb(246, 246, 247)",
      height: "fit-content",
      padding: 20,
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    loadingBox: {
      margin: "12% auto",
      textAlign: "center",
    },
    loading: {
      textAlign: "center",
      margin: "0 auto",
      color: "#ff2c46",
    },
  };
});

const Billing = () => {
  const classes = useStyles();
  const [clinicReportsData, setClinicReportsData] = useState([]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: "bold",
    },
  }));

  const clinicId = Cookies.get("clinic_id");
  const clinicName = Cookies.get("clinic_name");

  const { t, i18n } = useTranslation();
  const [disputeFilesKey, setDisputeFilesKey] = useState([]);
  const [disputeNote, setDisputeNote] = useState("");
  const [reportId, setReportId] = useState(null);
  const [invoice, setInvoice] = useState({ isOpen: false });
  const [isArabic, setIsArabic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disputeDialog, setDisputeDialog] = useState(false);
  const [acceptDialog, setAcceptDialog] = useState(false);
  const [pending, setPending] = useState(false);

  let align = isArabic ? "right" : "left";
  const { isOpen} = invoice;
  const onChange = (e) => {
    const { value } = e.target;
    setDisputeNote(value);
  };
  
  const onFileChange = (filesImage1) => {
    if (!filesImage1.length) {
      return;
    }
    let ext = filesImage1[filesImage1.length - 1].path.split(".").pop();
    let imageType = filesImage1[filesImage1.length - 1].type;
    let url2 = "";
    const form_data = new FormData();
    form_data.append("content_type", imageType);
    axios
      .post(`/admin/v1/images/s3`, form_data)
      .then((res) => {
        setDisputeFilesKey((prev) => [...prev, {ext,key:res.data.key}]);
        url2 = res.data.url;
        axiosAws
          .put(url2, filesImage1[filesImage1.length - 1], {
            headers: {
              "x-amz-acl": ["public-read-write"],
              "Content-Type": imageType,
            },
          })
          .catch((err) => console.log("Error while uploading file 1", err));
      })
      .catch((err) => console.log("Error while uploading file 2", err));
  };
  const getClinicReports = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/admin/v1/clinic-report/${clinicId}`);
      if (response) {
        const { data } = response.data;
        if (data) {
          setClinicReportsData(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const openInvoiceDialogHandler = (reportId) => {
    setReportId(reportId);
    setInvoice((prevState) => ({ ...prevState, isOpen: true }));
  };
  const closeInvoiceDialogHandler = () => {
    setInvoice((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleAcceptReportDialog = (reportId) => {
    setReportId(reportId);
    setAcceptDialog(true);
  };

  const handleDownloadReport = (link, date) => {
    saveAs(link, `${clinicName}-${date}.pdf`);
  };

  const handleDownloadPaymentConfirmation = (link, date) => {
    saveAs(link, `${clinicName}-${date}.${link.split(".").pop()}`);
  };

  const handleDisputeReportDialog = (reportId) => {
    setDisputeNote("");
    setDisputeDialog(true);
    setReportId(reportId);
  };

  const onInvoiceFileSave = async () => {
    setInvoice({
      isOpen: false,
    });
    try {
      setPending(true);
      const response = await axios.post(
          `/admin/v1/clinic-invoice/create`,
          {
            clinic_report_id: reportId,
            files: disputeFilesKey,
          },
      );
      if (response) {
        const {success} = response.data;
        if (success) {
          toast.success(t("invoice-uploaded"), 3000);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
      setDisputeFilesKey([]);
      handleCloseDialog();
    }
  };

  const handleCloseDialog = (type) => {
    if (type === "dispute") {
      setDisputeDialog(false);
    } else {
      setAcceptDialog(false);
    }
  };

  const handleAcceptReport = async () => {
    try {
      setPending(true);
      const response = await axios.post(`/admin/v1/clinic-report/${reportId}/accepted/`);
      if (response) {
        const {success} = response.data;
        if (success) {
          toast.success(t("report-accepted"), 3000);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
      handleCloseDialog();
      await getClinicReports();
    }
  };

  const handleDisputeReport = async () => {
    try {
      setPending(true);
      const response = await axios.post(
        `/admin/v1/clinic-dispute/create/`,
        {
          text: disputeNote,
          clinic_report_id: reportId,
          files: disputeFilesKey,
        },
      );
      if (response) {
        const {success} = response.data;
        if (success) {
          toast.success(t("dispute-uploaded"), 3000);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
      setDisputeFilesKey([]);
      handleCloseDialog("dispute");
      await getClinicReports();
    }
  };

  useEffect(() => {
    void getClinicReports();
  }, []);

  useEffect(() => {
    if (i18n.language === "ar") {
      setIsArabic(true);
    }
    if (i18n.language === "en") {
      setIsArabic(false);
    }
  }, [i18n.language, isArabic]);

  return (
    <Layout>
      {loading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress className={classes.loading} />
        </Box>
      ) : (
        <Box className={classes.boxWrapper}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell variant="head" align={align}>
                    {t("month")}
                  </StyledTableCell>
                  <StyledTableCell variant="head" align={align}>
                    {t("report-version")}
                  </StyledTableCell>
                  <StyledTableCell variant="head" align={align}>
                    {t("download-report")}
                  </StyledTableCell>
                  <StyledTableCell variant="head" align={align}>
                    {t("accept")}
                  </StyledTableCell>
                  <StyledTableCell variant="head" align={align}>
                    {t("dispute")}
                  </StyledTableCell>
                  <StyledTableCell variant="head" align={align}>
                    {t("invoice")}
                  </StyledTableCell>
                  <StyledTableCell variant="head" align={align}>
                    {t("payment-confirmation")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clinicReportsData.length > 0 ?
                  clinicReportsData.map(
                    (
                      {
                        id,
                        accepted,
                        accepted_at,
                        date,
                        aws_url,
                        aws_url_link,
                        dispute_id,
                        dispute,
                        version,
                        invoice,
                        latest_version,
                        payment_confirmation_invoice
                      },
                      index
                    ) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align={align} component="th" scope="row">
                          {moment(date).format("MMMM YYYY")}
                        </TableCell>
                        <TableCell align={align}>{`V.${version}`}</TableCell>
                        <TableCell align={align}>
                          <Button
                              variant="contained"
                              onClick={() => handleDownloadReport(aws_url_link, date)}
                          >
                            {t("download-report")}
                          </Button>
                        </TableCell>
                        <TableCell align={align} component="th" scope="row">
                          {!dispute_id ? accepted ? (
                            `${t("accepted_on")} ${moment(accepted_at).format(
                              "DD MMMM YYYY"
                            )}`
                          ) : (
                            latest_version &&<Button
                              variant="contained"
                              disabled={!latest_version}
                              onClick={() => handleAcceptReportDialog(id)}
                            >
                              {t("accept")}
                            </Button>
                          ) : ""}
                        </TableCell>
                        <TableCell align={align}>
                          {!accepted ? dispute_id ? (
                            `${t("disputed_on")} ${moment(
                              dispute.created_at
                            ).format("DD MMMM YYYY")}`
                          ) : (
                            latest_version &&<Button
                              disabled={!latest_version}
                              onClick={() => handleDisputeReportDialog(id)}
                              variant="contained"
                            >
                              {t("dispute")}
                            </Button>
                          ) : ""}
                        </TableCell>
                        <TableCell align={align}>
                          {accepted === 1 ? (
                              <Button
                                  variant="contained"
                                  onClick={() => openInvoiceDialogHandler(id)}
                              >
                                {t("upload-invoice")}
                              </Button>
                          ) : (
                              <Button disabled
                                      variant="contained"
                                      onClick={() => openInvoiceDialogHandler(id)}
                              >
                                {t("upload-invoice")}
                              </Button>
                          )}
                        </TableCell>
                        <TableCell align={align}>
                          <Button
                              disabled={!payment_confirmation_invoice}
                              variant="contained"
                              onClick={() => handleDownloadPaymentConfirmation(payment_confirmation_invoice?.files_link, date)}
                          >
                            {t("download")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  ) :
                    <TableCell colSpan={6} rowSpan={3}>
                      <Box style={{textAlign: "center"}}>
                        <AssessmentIcon style={{color: '#ff2c46', fontSize: '5em'}}/>
                        <Typography>{t('no-reports')}</Typography>
                      </Box>
                    </TableCell>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <ConfirmationDialog
        open={disputeDialog}
        handleClose={() => handleCloseDialog("dispute")}
        handleConfirm={handleDisputeReport}
        confirmationButtonText={t("Confirm")}
        cancelButtonText={t("Cancel")}
        dialogTitle={t("dispute-details")}
        pending={pending}
      >
        <TextField
          key="disputeNotesKey"
          id="outlined-multiline-flexible"
          label={t("dispute-notes")}
          multiline
          maxRows={4}
          fullWidth
          type="text"
          onChange={onChange}
          value={disputeNote}
        />
        <div style={{ margin: "20px 0" }}></div>
        <DropzoneArea
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          filesLimit={10}
          onChange={(files) => onFileChange(files)}
          previewGridProps={{ container: { spacing: 1, direction: "row" } }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          previewText={t("selected-files")}
          dropzoneText={t("drag-drop")}
        />
      </ConfirmationDialog>
      <ConfirmationDialog
        open={acceptDialog}
        handleClose={handleCloseDialog}
        handleConfirm={handleAcceptReport}
        confirmationButtonText={t("Confirm")}
        cancelButtonText={t("Cancel")}
        dialogTitle={t("accept-dialog-title")}
        pending={pending}
      >
        {t("accept-dialog-context")}
      </ConfirmationDialog>
      <DropzoneDialog
        cancelButtonText={t("Cancel")}
        submitButtonText={t("submit")}
        filesLimit={1}
        maxFileSize={5000000}
        open={isOpen}
        onClose={closeInvoiceDialogHandler}
        onSave={async () => {
          await onInvoiceFileSave();
        }}
        onDrop={(files) => onFileChange(files)}
        showPreviews={true}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
        previewChipProps={{ classes: { root: classes.previewChip } }}
        previewText={t("selected-files")}
        showFileNamesInPreview={true}
        dialogTitle={t("Upload-Files")}
        dropzoneText={t("drag-drop")}
        acceptedFiles={['application/pdf', 'image/jpeg', 'image/jpg']}
      />
    </Layout>
  );
};

export default Billing;
